<template>
  <v-card>
    <v-toolbar
      color="primary"
      dark
      flat
    >
      <v-toolbar-title>Manual Utilizare - Management Produse</v-toolbar-title>
    </v-toolbar>
    <v-tabs class="" left vertical>

      <v-tab class="justify-start">
        <span class="ml-2 font-weight-bold">Produse de baza</span>
      </v-tab>
      <v-tab class="justify-start">
        <span class="ml-2 font-weight-bold">Categorii</span>
      </v-tab>
      <v-tab class="justify-start">
        <span class="ml-2 font-weight-bold">Calitati</span>
      </v-tab>
      <v-tab class="justify-start">
        <span class="ml-2 font-weight-bold">Garantii extra</span>
      </v-tab>
      <v-tab class="justify-start">
        <span class="ml-2 font-weight-bold">Branduri</span>
      </v-tab>
      <v-tab class="justify-start">
        <span class="ml-2 font-weight-bold">Grupuri de atribute</span>
      </v-tab>
      <v-tab class="justify-start">
        <span class="ml-2 font-weight-bold">Tipuri de atribute</span>
      </v-tab>

      <v-tab class="justify-start">
        <span class="ml-2 font-weight-bold">Componente</span>
      </v-tab>
      <v-tab class="justify-start">
        <span class="ml-2 font-weight-bold">Tipuri de atribute</span>
      </v-tab>


      <v-tab-item class="ml-5">
        <base-products/>
      </v-tab-item>
      <v-tab-item class="ml-5">
        <categories/>
      </v-tab-item>
      <v-tab-item class="ml-5">
        <qualities/>
      </v-tab-item>

    </v-tabs>
  </v-card>


</template>

<script>
import BaseProducts from './sections/BaseProducts'
import Categories from './sections/Categories'
import Qualities from './sections/Qualities'

export default {
  name: 'index',
  components: {
    Qualities,
    Categories,
    BaseProducts
  }
}
</script>

<!--suppress CssUnusedSymbol -->
<style>
.homepage-settings .v-tabs-slider-wrapper {
  right: 0;
  left: inherit !important;
}
</style>
