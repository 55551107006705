<template>
  <v-card flat>
    <v-card-subtitle><h5 class="text-primary font-weight-bold">Adaugarea unui produs de baza</h5></v-card-subtitle>
    <v-card-text>
      <p>
        dsfsdf
      </p>

      <expandable-image image="https://picsum.photos/id/11/500/300"/>
      <p>
        dsfsdf
      </p>
      <p>
        dsfsdf
      </p>
    </v-card-text>
  </v-card>
</template>

<script>
  import ExpandableImage from "../../components/ExpandableImage";
  export default {
    name: "BaseProducts",
    components: {ExpandableImage}
  }
</script>
